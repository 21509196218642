<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Change Password</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="user.id"
                />
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Login:</label>
                    <div class="col-lg-6 col-form-label font-weight-bold">{{ user.login }}</div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Password:</label>
                    <div class="col-lg-9 row">
                        <div class="col-7">
                            <input
                                :type="type"
                                class="form-control"
                                v-model="user.newPassword"
                                v-validate="'required|min:4|max:50'"
                                data-vv-as="Password"
                                ref="password"
                                name="password"
                                :class="{'is-invalid': errors.has('password') }"/>
                            <div v-show="errors.first('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                        </div>
                        <div class="col-auto">
                            <button type="button" @click="openShow()" class="btn btn-default btn-bold btn-upper btn-font-md mr-2">
                                <span v-if="viewClosed">
                                    <i class="la la-eye-slash"></i>
                                </span>
                                <span v-else>
                                    <i class="la la-eye"></i>
                                </span>
                            </button>
                        </div>
                        <div class="col-3">
                            <button type="button" @click="generate()" class="col-auto btn btn-default btn-bold btn-upper btn-font-md"><i class="la la-key"></i> Generate</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">Save</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");

export default {
  components: {
  },
  data() {
    return {
        user : {
            login: null,
            newPassword: null
        },
        viewClosed: true,
        type: 'password'
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    openShow: function(val){
        this.viewClosed = (val != undefined) ? val : !this.viewClosed;
        this.type = (this.viewClosed) ? 'password' : 'text';
    },
    generate: function(){
        var generator = require('generate-password');
        var password = generator.generate({
            length: 6,
            numbers: true
        });
        this.user.newPassword = password;
        this.openShow(false);
    },
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("update:showModalPassword", false);
    },
    updateData: function (data) {
        this.user.login = data.login;
    },
    submitData: function () {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Perubahan Password akan tersimpan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.update(vx.user);
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
          return;
        }
      });
    },
    async update(payload) {
      var vx = this;

      await UserRepository.forcePassword(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan Password berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan Password gagal disimpan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
        this.updateData(this.modalData);
    }
  }
}
</script>

<style>

</style>
